import React from "react"
import PropTypes from "prop-types"

import { EMPTY_CATEGORY_DEFAULT } from "../../constants"
import IconChevronDown from "../SVG/IconChevronDown"

const ArticlesFilter = ({ categories, handleChange, emptyLabel }) => {
  return (
    <div className="Select">
      <select
        className="Select__inner"
        onChange={event => {
          handleChange(event)
        }}
      >
        <option value={EMPTY_CATEGORY_DEFAULT}>{emptyLabel}</option>
        {categories.map((item, index) => {
          return (
            <option key={`${item.id} ${index}`} value={item.id}>
              {item.title}
            </option>
          )
        })}
      </select>
      <div className="Select__icon">
        <div className="w-5 h-3">
          <IconChevronDown />
        </div>
      </div>
    </div>
  )
}

ArticlesFilter.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  emptyLabel: PropTypes.string,
}

ArticlesFilter.defaultProps = {
  emptyLabel: EMPTY_CATEGORY_DEFAULT,
}

export default ArticlesFilter
