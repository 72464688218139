import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"

const NewsCard = ({ item }) => {
  const { title, publishedDate, image, body, newsCategory, uri } = item

  let imageData = {}
  if (image.length && image[0].localFile) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].title,
    }
  }

  const date = new Date(publishedDate)
  const formattedDate = date.toLocaleDateString("en-GB")

  return (
    <TransitionLinkFade to={`/${uri}`}>
      <div className="rounded-xl overflow-hidden bg-white h-full flex flex-col">
        {imageData.image && (
          <GatsbyImage
            className="h-0 pb-4:3 w-full transform-gpu"
            image={imageData.image}
            alt={imageData.alt}
          />
        )}
        {image.length && image[0].url && (
          <div className="h-0 pb-4:3 w-full relative">
            <img
              src={image[0].url}
              alt={image[0].title}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </div>
        )}
        <div className="px-4 py-5 flex flex-col flex-1">
          <div className="space-y-2">
            <div className="text-sm text-opacity-80">{formattedDate}</div>
            <h4 className="font-semibold text-lg">{title}</h4>
            {body && <div className="text-sm">{body}</div>}
          </div>
          {newsCategory.length > 0 && (
            <div className="flex space-x-2 pt-6 mt-auto">
              {newsCategory.map((item, index) => {
                return (
                  <div
                    key={`${index} ${item.title}`}
                    className="font-semibold text-sm px-3 py-1.5 bg-blue-400 rounded-full"
                  >
                    {item.title}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </TransitionLinkFade>
  )
}

NewsCard.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  publishedDate: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.arrayOf(PropTypes.shape({})),
  newsCategory: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
}

export default NewsCard
