import React from "react"
import PropTypes from "prop-types"

import IconArrow from "../SVG/IconArrow"

import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"

const EventCard = ({ item }) => {
  const { title, startDate, image, body, uri, address, isOnline, isFree } = item
  const { city, state, postCode } = address

  const formattedStartDate = new Date(startDate).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })

  const renderDateLocation = () => {
    if (isOnline) {
      return `${formattedStartDate} | Online`
    }
    return `${formattedStartDate} | ${city} ${state} ${postCode}`
  }

  return (
    <TransitionLinkFade to={`/events/${uri}`}>
      <div className="flex h-full flex-col overflow-hidden rounded-xl bg-white">
        <div className="pb-4:3 relative h-0 w-full">
          {image && title && (
            <img
              src={image}
              alt={title}
              className="absolute inset-0 h-full w-full object-cover"
            />
          )}
          <div className="absolute bottom-0 bg-yellow-500 py-1 px-2 text-xs text-black">
            {isFree ? "Free" : "Paid"}
          </div>
        </div>

        <div className="flex flex-1 flex-col px-4 py-5">
          <div className="space-y-2">
            <h4 className="text-lg font-semibold">{title}</h4>
            {body && <div className="text-sm">{body}</div>}
          </div>
        </div>
        <div className="tp-2 px-4 pb-5 text-sm font-bold text-blue-500">
          {renderDateLocation()}
        </div>
        <div className="flex justify-between bg-blue-500 px-4 py-5 align-middle font-semibold text-white">
          <h6>View Details</h6>
          <div className="my-auto">
            <IconArrow />
          </div>
        </div>
      </div>
    </TransitionLinkFade>
  )
}

EventCard.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  startDate: PropTypes.string,
  body: PropTypes.string,
  isOnline: PropTypes.bool,
  isFree: PropTypes.bool,
  image: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    postCode: PropTypes.string,
  }),
}

export default EventCard
