import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { BLUE, EMPTY_CATEGORY_DEFAULT } from "../constants"
import { createEventsSlug } from "../utils"

import Layout from "../components/Layout/Layout"
import ArticlesFilter from "../components/Articles/ArticlesFilter"
import ArticlesList from "../components/Articles/ArticlesList"

const DEFAULT_PAGE_SIZE = 6

const EventsPage = props => {
  const [location, setLocation] = useState(null)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [allArticles, setAllArticles] = useState([])
  const [articles, setArticles] = useState([])

  const { metaTitle, metaDescription } =
    props.data.craftEventsIndexEventsIndexEntry
  const { events, pageInfo } = props.data.allEventbriteEvents

  const cities = Array.from(
    new Set(
      events.map(event => event?.venue?.address?.city).filter(city => !!city)
    )
  )

  useEffect(() => {
    const mappedEvents = events.map(item => ({
      title: item?.name?.text,
      startDate: item?.start?.local,
      image: item?.logo?.original?.url,
      body: item?.summary,
      uri: createEventsSlug(item?.name?.text, item?.start?.local),
      isOnline: item?.online_event,
      isFree: item?.is_free,
      address: {
        city: item?.venue?.address?.city,
        state: item?.venue?.address?.region,
        postCode: item?.venue?.address?.postal_code,
      },
    }))

    setAllArticles(mappedEvents)
  }, [events])

  // Filtered and paged articles.
  useEffect(() => {
    const displayArticles = allArticles
      .filter(article => location === null || article.address.city === location)
      .slice(0, pageSize)

    setArticles(displayArticles)
  }, [pageSize, location, allArticles])

  const handleLocationFilter = event => {
    const eventVal = event.target.value
    const val = eventVal === EMPTY_CATEGORY_DEFAULT ? null : cities[eventVal]
    setLocation(val)
  }

  const handleLoadMore = () => {
    setPageSize(pageSize + DEFAULT_PAGE_SIZE)
  }

  const cityFilters = cities.map((city, index) => ({
    id: index,
    title: city,
  }))

  return (
    <Layout
      title={metaTitle || "Events"}
      description={metaDescription}
      theme={BLUE}
      location={props.location}
    >
      <div className="js-transition-link-target" data-theme={BLUE}>
        <div className="px-ogs py-20 md:grid md:grid-cols-12 md:gap-x-gs lg:py-30">
          <div className="md:col-span-12 xl:col-span-10 xl:col-start-2">
            <div className="mb-4 space-y-8 md:flex md:items-center md:justify-between md:space-y-0 md:space-x-gs lg:mb-6">
              <h1 className="text-4xl font-semibold text-blue-500 lg:text-5xl">
                {metaTitle}
              </h1>
              <div className="flex space-x-5">
                <ArticlesFilter
                  emptyLabel="All Locations"
                  categories={cityFilters}
                  handleChange={handleLocationFilter}
                />
              </div>
            </div>
            <ArticlesList articles={articles} isEvent />
          </div>
          {pageInfo.totalCount > pageSize && (
            <div className="md:col-span-12 xl:col-span-10 xl:col-start-2">
              <div className="inline-flex w-full justify-center">
                <button
                  onClick={handleLoadMore}
                  className="Button Button--primary Button--larger"
                >
                  Load More Events
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default EventsPage

export const pageQuery = graphql`
  query {
    craftEventsIndexEventsIndexEntry {
      metaTitle
      metaDescription
    }
    allEventbriteEvents(
      filter: { listed: { eq: false }, status: { regex: "/^(live|started)$/" } }
      sort: { order: ASC, fields: start___local }
    ) {
      events: nodes {
        url
        is_free
        capacity
        status
        summary
        name {
          text
        }
        end {
          timezone
          local(locale: "AU")
        }
        start {
          timezone
          local(locale: "AU")
        }
        logo {
          original {
            url
          }
        }
        online_event
        venue {
          address {
            city
            region
            postal_code
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`
