import React from "react"

const IconChevronDown = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 8.7 5.1">
      <path d="M0,0.7L0.7,0l3.6,3.6L8,0l0.7,0.7L4.4,5.1L0,0.7z" />
    </svg>
  )
}

export default IconChevronDown
