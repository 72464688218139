export const convertGatsbySourceCraftIdtoCraftId = gatsbySourceId => {
  const firstColonIndex = gatsbySourceId.indexOf(":")
  const lastColonIndex = gatsbySourceId.lastIndexOf(":")
  return parseInt(gatsbySourceId.substring(firstColonIndex + 1, lastColonIndex))
}

export const createEventsSlug = (title, date) => {
  const formattedDate = new Date(date)
    .toLocaleDateString("en-GB")
    .replaceAll("/", "-")

  const formattedTitle = title
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "")
    .toLowerCase()

  const slug = `${formattedDate}-${formattedTitle}`
  return slug
}
