import React from "react"
import PropTypes from "prop-types"

import NewsCard from "../News/NewsCard"
import EventCard from "../Events/EventsCard"

const ArticlesList = ({ articles, isEvent = false }) => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-gs my-16">
      {articles.map((item, index) =>
        isEvent ? (
          <EventCard key={`${item.title}-${index}`} item={item} />
        ) : (
          <NewsCard key={`${item.title}-${index}`} item={item} />
        )
      )}
    </div>
  )
}

ArticlesList.propTypes = {
  isEvent: PropTypes.bool,
  articles: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        publishedDate: PropTypes.string,
        body: PropTypes.string,
        image: PropTypes.arrayOf(PropTypes.shape({})),
        newsCategory: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
          })
        ),
      }),
      PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        startDate: PropTypes.string,
        body: PropTypes.string,
        isOnline: PropTypes.bool,
        isFree: PropTypes.bool,
        image: PropTypes.string,
        address: PropTypes.shape({
          city: PropTypes.string,
          state: PropTypes.string,
          postCode: PropTypes.string,
        }),
      }),
    ])
  ),
}

export default ArticlesList
